<template>
  <article class="digitalizador">
    <section v-bind:style="{ opacity: loading ? 0 : 1 }">
      <aside :style="{ flexBasis: asideWidth + '%' }">

        <div>
          <v-row justify="space-between" align="center" class="mb-0">
            <v-col cols="12" md="6" class="d-flex align-center">
              <v-btn icon color="primary" class="mr-2" @click="goBack">
                <v-icon>{{ icons.arrowLeft }}</v-icon>
              </v-btn>
              <v-icon color="primary" class="mr-2">mdi-warehouse</v-icon>
              <h3 class="text-subtitle-2 font-weight-bold mb-0">{{ nombreBodega }}</h3>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="6">
              <v-menu v-model="menuFecha" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="fecha" label="Fecha Formulación" prepend-icon="mdi-calendar" readonly dense
                    outlined hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="fecha" @input="menuFecha = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider></v-divider>


          <article class="paciente">
            <v-card elevation="1">
              <v-card-title class="text-caption font-weight-bold">Paciente</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select v-model="tipoDocumentoSeleccionado" :items="tiposDocumentos" item-value="tipoDocumento"
                      item-text="descripcion" label="Tipo de Documento" required dense outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="documento" @input="onInputUsuario" label="Documento" required dense outlined
                      hide-details="auto" class="text-caption"></v-text-field>

                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field v-model="nombre" label="Nombre" required disabled dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" v-show="false">
                    <v-text-field v-model="estado" label="Estado" required disabled dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1">
                    <div class="icons">
                      <figure v-if="usuarioConfirmado" class="ico-check" title="Confirmado"><v-icon
                          class="me-2">check</v-icon>
                      </figure>
                      <figure v-if="!usuarioConfirmado" title="Usuario no confirmado" class="ico-error"><v-icon
                          class="me-2">priority_high</v-icon></figure>
                    </div>
                    <v-icon v-if="usuarioConfirmado" class="text-success">mdi-check-circle</v-icon>
                    <v-icon v-if="!usuarioConfirmado" class="text-error">mdi-alert-circle</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </article>
          <article class="medico">
            <v-card elevation="1">
              <v-card-title class="text-caption font-weight-bold">Médico</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="documentoMedico" @input="onInputMedico" label="Documento" hide-details
                      required dense outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-text-field v-model="nombreMedico" label="Nombre" hide-details required dense outlined
                      :disabled="isDisabledMedico"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="icons">
                      <figure v-if="medicoConfirmado" class="ico-check" title="Confirmado"><v-icon
                          class="me-2">check</v-icon>
                      </figure>
                      <figure v-if="!medicoConfirmado" title="Médico no confirmado" class="ico-warning"><v-icon
                          class="me-2">warning</v-icon></figure>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </article>
          <article class="ips">
            <v-card elevation="1">
              <v-card-title class="text-caption font-weight-bold">IPS</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="pagador" @input="onInputIps" label="NIT Ips" hide-details required dense
                      outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-text-field v-model="ips" label="Nombre Ips" hide-details required :disabled="isDisabledIps" dense
                      outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="icons">
                      <figure v-if="ipsConfirmado" class="ico-check" title="Confirmado"><v-icon
                          class="me-2">check</v-icon>
                      </figure>
                      <figure v-if="!ipsConfirmado" title="Médico no confirmado" class="ico-warning"><v-icon
                          class="me-2">warning</v-icon></figure>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </article>
          <article class="ips">
            <v-card elevation="1">
              <v-card-title class="text-caption font-weight-bold">Diagnóstico</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="codigoCIE" @input="searchDiagnostico" label="Código CIE" hide-details
                      required dense outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-text-field v-model="descripcionCIE" label="Descripción" hide-details required disabled outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="icons">
                      <figure v-if="diagnosticoConfirmado" class="ico-check" title="Confirmado"><v-icon
                          class="me-2">check</v-icon>
                      </figure>
                      <figure v-if="!diagnosticoConfirmado" title="Diagnóstico no confirmado" class="ico-warning">
                        <v-icon class="me-2">warning</v-icon>
                      </figure>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </article>
          <article class="medicamentos" v-if="false">
            <h4>Medicamentos</h4>
            <ul>
              <li v-for="(medicamento, index) in medicamentos" :key="index">
                <div>
                  <v-text-field v-model="medicamento.nombre" label="Nombre" hide-details required></v-text-field>
                </div>
                <div>
                  <v-text-field v-model="medicamento.cantidad" label="Cantidad" hide-details required>
                  </v-text-field>
                </div>
                <div>
                  <v-text-field v-model="medicamento.vigencia" label="Vigencia" hide-details required>
                  </v-text-field>
                </div>
              </li>
            </ul>
          </article>
          <footer>
            <v-btn variant="elevated" color="primary" :disabled="!usuarioConfirmado" @click="generarExpediente()">
              Generar Expediente
            </v-btn>
          </footer>
        </div>
      </aside>
      <div class="divider" @mousedown="startDrag"></div>
      <article :style="{ flexBasis: articleWidth + '%' }">
        <section>
          <div ref="viewer" class="viewer-container">
            <div id="pdf-viewer" v-if="loadingPdf == 3"></div>
            <pdf v-for="i in numPages" :key="i" :src="src" :page="i" v-show='pdfView'></pdf>
          </div>
        </section>
        <footer>
        </footer>
      </article>
    </section>
    <div class="loading" v-if="loading">
      <div class="loadingio-spinner-spinner-2by998twmg8">
        <div class="ldio-yzaezf3dcmj">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <h4>{{ loadingText }}</h4>
    </div>
    <v-snackbar v-model="snackbar" :color="snackbarColor" bottom timeout="6000" multi-line>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import PDFObject from 'pdfobject'
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import Swal from 'sweetalert2'
import pdf from 'vue-pdf'
import {  
  mdiArrowLeft

} from '@mdi/js';

export default {
  name: 'Index',
  components: {
    pdf
  },
  data: () => ({
    icons: {
      arrowLeft: mdiArrowLeft
    },
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    usuario: "",
    name: "",
    loadingText: "Extrayendo información de la formula",

    documento: "",
    nombre: "",
    estado: "",
    documentoMedico: "",
    nombreMedico: "",
    pagador: "",
    ips: "",

    indicacion: "",
    observaciones: "",
    producto: "",
    cantidad: "",
    duracion: "",
    fecha: "",
    asideWidth: 50,
    articleWidth: 50,
    isDragging: false,
    diagnosticos: [],
    medicamentos: [],
    datosIcr: [],
    entidad_formulante: "",

    searchQuery: '',
    timeout: null,

    loading: true,
    viewer: null,
    isDisabledMedico: false,
    isDisabledIps: false,

    file1: "1655146978601.pdf",
    file2: "1655148110018.pdf",
    url: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=View&filename=",
    urlBase64: "https://0qvhf3ep8b.execute-api.us-east-1.amazonaws.com/prod?function=Base64&filename=",

    usuarioConfirmado: false,
    medicoConfirmado: false,
    ipsConfirmado: false,
    bodegaId: "",
    nombreBodega: "",
    fileName: "",
    pdfBase64: "",
    numPages: undefined,
    pdfView: false,
    loadingPdf: 1,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: 'success',
    nombreArchivo: "",
    tipoDocumentoSeleccionado: "",
    tiposDocumentos: [],
    diagnosticoConfirmado: false,
    codigoCIE: "",
    descripcionCIE: "",
    nombrePrestador: "",
  }),
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  created() {

    const savedAsideWidth = localStorage.getItem('asideWidth');
    const savedArticleWidth = localStorage.getItem('articleWidth');

    if (savedAsideWidth && savedArticleWidth) {
      this.asideWidth = parseFloat(savedAsideWidth);
      this.articleWidth = parseFloat(savedArticleWidth);
    }
  },
  mounted: async function () {
    this.usuario = this.auth.username;
    this.loading = true;
    this.name = this.$route.params.name;
    this.name = this.$route.query.name;
    await this.getTipoDocumento();

    await this.empresasUsuario();
    this.getBodega();
    await this.cargarFormulario();
  },
  beforeDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  },

  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
      "setEnterprise"
    ]),
    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    async cargarFormulario() {
      this.$http
        .get("msa-process-file/api/expediente/mostrarFormulaEscaneada?idFormulaEscaneada=" + this.name)
        .then((result) => {
          this.fileName = result.data.rutaArchivo.replace('dispensacion/', '');
          this.datosIcr = JSON.parse(result.data.dataJson);
          this.loadingPdf = 2;
          this.nombreArchivo = result.data.nombreArchivo;
          console.log(this.datosIcr)

          this.$http
            .get("msa-process-file/api/file/getFile?fileName=" + this.fileName)
            .then((result) => {
              if (!result.data) {
                throw new Error("El archivo PDF recibido está vacío.");
              }

              this.pdfUrl = "";
              this.loadingPdf = 3;
              this.pdfUrl = "data:application/pdf;base64," + result.data;
              this.isActive = true;
              setTimeout(() => {
                this.embedPDF();
                this.loading = false;
              }, 100)
              this.pdfView = true;


            })
            .catch((error) => {
              console.error("❌ Error al cargar el PDF:", error);
            });

          this.diagnosticos = this.datosIcr.diagnostico[0];
          this.medicamentos = this.datosIcr.productos_recetados;
          this.documento = this.datosIcr.datos_paciente.documento;
          const tipoDocPaciente = this.datosIcr.datos_paciente.tipo_documento;
          const encontrado = this.tiposDocumentos.find(item => item.tipoDocumento === tipoDocPaciente);

          if (encontrado) {
            this.tipoDocumentoSeleccionado = encontrado.tipoDocumento;
          }


          this.documentoMedico = this.datosIcr.datos_medico.documento;
          this.nombreMedico = this.datosIcr.datos_medico.nombre;

          this.pagador = this.datosIcr.nitEntidadFormulante;
          this.ips = this.datosIcr.entidad_formulante;
          this.nombrePrestador = this.datosIcr.entidad_formulante;
          this.fecha = this.datosIcr.fecha_formulacion;

          this.entidad_formulante = this.datosIcr.entidad_formulante;
          this.$http
            .get("msa-process-file/api/afiliado/list?tipoDocumento=" + this.tipoDocumentoSeleccionado + "&documento=" + this.documento + "&idEmpresa=" + this.dataEmpresas[0].idEmpresa)
            .then((result) => {
              console.log(result)
              if (result.data && result.data.nombreCompleto) {
                this.nombre = result.data.nombreCompleto;
                if (result.data.estadoAfiliadoValue == "A") {
                  this.usuarioConfirmado = true;
                }
              }
            });

          this.$http
            .get("msa-process-file/api/medico/list?documento=" + this.documentoMedico)
            .then((result) => {
              if (result.data && result.data.nombres) {
                this.nombreMedico = result.data.nombres + " " + result.data.apellidos;
                this.estadoMedico = true;
                this.medicoConfirmado = true;
              }
            });

          this.$http
            .get("msa-process-file/api/prestador/list?nit=" + this.pagador + "&nombrePrestador=" + this.nombrePrestador)
            .then((result) => {
              if (result.data && result.data.nombrePrestador) {
                console.log(result.data)
                this.ips = result.data.nombrePrestador;
                this.pagador = result.data.codigoHabilitacion;
                this.estadoIps = true;
                this.ipsConfirmado = true;
              }
            });
          console.log(this.diagnosticos.codigo)
          this.codigoCIE = this.diagnosticos.codigo;
          this.$http
            .get("msa-process-file/api/cie/list?cie=" + this.diagnosticos.codigo)
            .then((result) => {
              if (result.data && result.data.descripcionCIE) {
                console.log(result.data)
                this.codigoCIE = result.data.codigoCIE;
                this.descripcionCIE = result.data.descripcionCIE;
                this.diagnosticoConfirmado = true
              }
            });


          this.medicamentos.map((it) => {
            it.cantidad = (it.cantidad == "Descripción no especificada") ? "" : it.cantidad;
            it.vigencia = (it.vigencia == "Descripción no especificada") ? "" : it.vigencia;
          })
        });
    },
    goBack() {
      this.$router.push({ path: '/modules/expediente/bandeja/' });
    },
    embedPDF() {
      PDFObject.embed(this.pdfUrl, "#pdf-viewer");
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    redirectToFormulas() {
      this.$router.push({ path: '/modules/expediente/expedientes' });
    },
    getTipoDocumento() {
      this.$http.get(`msa-process-file/api/tipodocumento/list`)
        .then(({ data }) => {
          console.log('tp', data)
          this.tiposDocumentos = data;
        })
        .catch(err => console.log(err))
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          this.bodegaId = data.codigoBodega;
          this.nombreBodega = data.nombreBodega;
        })
        .catch(err => console.log(err))
    },
    generarExpediente() {
      let medicamentos = [];
      this.medicamentos.map((it) => {
        medicamentos.push({
          ...it,
          nombreProducto: it.nombre
        })
      })
      let data = {
        "tipoDocumento": this.tipoDocumentoSeleccionado,
        "documentoAfiliado": this.documento,
        "fechaFormulacion": this.fecha,
        "cie": this.codigoCIE,
        "codMipres": "",
        "bodega": {
          "bodegasId": {
            "idEmpresa": this.dataEmpresas[0].idEmpresa,
            "codigoBodega": this.bodegaId
          }
        },
        "tipoArchivo": "ATTACHMENT",
        "documentoMedico": this.documentoMedico,
        "prestador": this.pagador,
        "nombreArchivo": this.nombreArchivo,
        "detalleProductos": medicamentos
      }

      this.loading = true;
      this.loadingText = "Creando Expediente"
      console.log(data)
      this.$http.post('msa-process-file/api/expediente/crearExpediente', data)
        .then((data) => {
          console.log(data);
          this.loading = false;

          Swal.fire({
            title: 'Expedientes',
            text: 'El expediente ha sido almacenado.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.$router.push({ path: '/modules/expediente/bandeja' });
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });


    }, startDrag() {
      this.isDragging = true;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(e) {
      if (this.isDragging) {
        const containerWidth = this.$el.offsetWidth;
        const mouseX = e.clientX;
        const newAsideWidth = ((mouseX / containerWidth) * 100) - 4;
        const newArticleWidth = 100 - newAsideWidth;

        if (newAsideWidth > 10 && newArticleWidth > 10) {
          this.asideWidth = newAsideWidth;
          this.articleWidth = newArticleWidth;
        }
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);

      localStorage.setItem('asideWidth', this.asideWidth);
      localStorage.setItem('articleWidth', this.articleWidth);

      this.refreshViewer();
    },

    refreshViewer() {
      if (this.viewer) {
        this.viewer.update(); // Si la librería tiene un método de actualización
      }
    },

    agregarMedico() {
      this.medicoConfirmado = true;
    },

    onInputUsuario() {
      this.searchUsuario();
    },
    searchUsuario() {
      this.snackbarMessage = "Buscando afiliado...";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 8000;
      this.$http
        .get("msa-process-file/api/afiliado/list?tipoDocumento=" + this.tipoDocumentoSeleccionado + "&documento=" + this.documento + "&idEmpresa=" + this.dataEmpresas[0].idEmpresa)
        .then((result) => {
          console.log(result)
          if (result.data && result.data.nombreCompleto) {
            this.nombre = result.data.nombreCompleto;
            this.usuarioConfirmado = result.data.estadoAfiliadoValue === "A";
          } else {
            this.nombre = "";
            this.snackbarMessage = "⚠️ El afiliado no existe.";
            this.snackbarColor = "error";
          }
        })
        .catch(() => {
          this.snackbarMessage = "❌ Error al buscar afiliado.";
          this.snackbarColor = "error";
        });
    }, onInputMedico() {
      this.searchMedico();
    }, searchMedico() {
      this.snackbarMessage = "Buscando medico...";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 8000;

      this.$http
        .get("msa-process-file/api/medico/list?documento=" + this.documentoMedico)
        .then((result) => {
          if (result.data && result.data.nombres) {
            this.nombreMedico = result.data.nombres + " " + result.data.apellidos;
            this.estadoMedico = true;
            this.medicoConfirmado = true;
          } else {
            this.nombreMedico = "";
            this.estadoMedico = false;
            this.snackbarMessage = "⚠️ El médico no existe.";
            this.snackbarColor = "error";
          }
        })
        .catch(() => {
          this.snackbarMessage = "❌ Error al buscar médico.";
          this.snackbarColor = "error";
        });
    },

    onInputIps() {
      this.searchIps();
    },

    searchIps() {
      this.snackbarMessage = "Buscando IPS...";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 8000;

      this.$http
        .get("msa-process-file/api/prestador/list?nit=" + this.pagador + "&nombrePrestador=" + this.nombrePrestador)
        .then((result) => {
          if (result.data && result.data.nombrePrestador) {
            this.ips = result.data.nombrePrestador;
            this.estadoIps = true;
            this.pagador = result.data.codigoHabilitacion;
            this.ipsConfirmado = true;
          } else {
            this.ips = "";
            this.estadoIps = false;
            this.snackbarMessage = "⚠️ La IPS no existe.";
            this.snackbarColor = "error";
          }
        })
        .catch(() => {
          this.snackbarMessage = "❌ Error al buscar IPS.";
          this.snackbarColor = "error";
        });
    },
    searchDiagnostico() {
      this.snackbarMessage = "Buscando diagnóstico...";
      this.snackbarColor = "info";
      this.snackbar = true;
      this.snackbarTimeout = 8000;
      console.log(this.codigoCIE)

      this.$http.get("msa-process-file/api/cie/list?cie=" + this.codigoCIE)
        .then((result) => {
          result.data.confirmado = false;
          console.log(result.data)
          if (result.data && result.data.descripcionCIE) {
            console.log(result.data)
            this.codigoCIE = result.data.codigoCIE;
            this.descripcionCIE = result.data.descripcionCIE;
            this.diagnosticoConfirmado = true
          } else {
            this.snackbarMessage = "⚠️ El diagnóstico no existe.";
            this.snackbarColor = "error";
          }
        })
        .catch(() => {
          this.snackbarMessage = "❌ Error al buscar diagnóstico.";
          this.snackbarColor = "error";

        });
    },
    createViewer() {
      this.viewer = new Viewer(this.$refs.viewer, {
        viewed: () => {
          const containerWidth = this.viewer.element.parentNode.clientWidth;
          const containerHeight = this.viewer.element.parentNode.clientHeight;
          const imageWidth = this.viewer.image.width;
          const imageHeight = this.viewer.image.height;

          const zoomX = containerWidth / imageWidth;
          const zoomY = containerHeight / imageHeight;
          let optimalZoom = Math.min(zoomX, zoomY);
          optimalZoom = .5;
          this.viewer.zoomTo(optimalZoom);
          this.viewer.moveTo(0, 0);
        },
        inline: true,
        navbar: true,
        toolbar: true,
        title: true,
      });
    }
  }
}
</script>

<style scoped>
ul,
ol {
  list-style: none;
}

.viewer-toolbar {
  display: none;
}

.divider {
  width: 10px;
  cursor: col-resize;
  background-color: #ccc;
  height: 100%;
}

article.digitalizador {
  padding: 0;
  width: 100%;
}

article.digitalizador .icons {
  display: flex;
  justify-content: flex-end;
  position: relative;
  /* position: absolute; */
  /* right: 20px; */
  /* top: 0; */
  width: 60px;
}

article.digitalizador .icons figure {
  top: 11px;
  position: absolute;
}

article.digitalizador .ico-check {
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;

}

article.digitalizador .ico-check i {
  border-radius: 50%;
  border: 1px solid green;
  color: green;
  padding: .2rem;
}

article.digitalizador .ico-warning {
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;

}

article.digitalizador .ico-warning i {
  border-radius: 50%;
  border: 1px solid orange;
  color: orange;
  padding: .2rem;
}

article.digitalizador .ico-error {
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;

}

article.digitalizador .ico-error i {
  border-radius: 50%;
  border: 1px solid red;
  color: red;
  padding: .2rem;
}

article.digitalizador .ico-more {
  font-size: 1.2rem;
  padding: .2rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

article.digitalizador .ico-more i {
  border-radius: 50%;
  border: 1px solid var(--v-primary-base);
  color: var(--v-primary-base);
  padding: .2rem;
}


#viewer #toolbar {
  display: none !important;
}

article.digitalizador>section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0;
  height: calc(100vh - 69px);

}


article.digitalizador>section>aside {
  width: 100%;
  height: calc(100vh - 69px);
  overflow-y: auto;
}

article.digitalizador>section>aside>div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  padding: 1%;
}

article.digitalizador>section>aside>div>h3 {
  padding-left: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0D47A1;
}

article.digitalizador>section>aside>div>article {
  margin-bottom: .4rem;
}

article.digitalizador>section>aside>div>article h4 {
  padding-left: 5px;
  max-width: fit-content;
}

article.digitalizador>section>aside>div>article>ul {
  padding-left: 5px;

}

article.digitalizador>section>aside>div>article>ul>li {
  display: flex;
  justify-content: center;
  position: relative;
}

article.digitalizador>section>aside>div>article.paciente>ul>li>div:first-child,
article.digitalizador>section>aside>div>article.medico>ul>li>div:first-child {
  width: 80px;
  min-width: 80px;
}

article.digitalizador>section>aside>div>article.medico>ul>li>div:nth-child(2),
article.digitalizador>section>aside>div>article.paciente>ul>li>div:nth-child(2) {
  width: 20%
}

article.digitalizador>section>aside>div>article.paciente>ul>li>div:nth-child(3) {
  width: 60%
}

article.digitalizador>section>aside>div>article.medico>ul>li>div:nth-child(3) {
  width: 80%
}

article.digitalizador>section>aside>div>article.paciente>ul>li>div:nth-child(4) {
  width: 20%
}


article.digitalizador>section>aside>div>article.ips>ul>li>div:first-child,
article.digitalizador>section>aside>div>article.medico>ul>li>div:first-child {
  width: 80px;
  min-width: 80px;
}

article.digitalizador>section>aside>div>article.medico>ul>li>div:nth-child(2),
article.digitalizador>section>aside>div>article.ips>ul>li>div:nth-child(2) {
  width: 40%
}

article.digitalizador>section>aside>div>article.ips>ul>li>div:nth-child(3) {
  width: 60%
}

article.digitalizador>section>aside>div>article.medico>ul>li>div:nth-child(3) {
  width: 80%
}

article.digitalizador>section>aside>div>article.ips>ul>li>div:nth-child(4) {
  width: 20%
}

article.digitalizador>section>aside>div>article.diagnostico>ul>li>div:nth-child(1) {
  width: 20%
}

article.digitalizador>section>aside>div>article.diagnostico>ul>li>div:nth-child(2) {
  width: 80%
}

article.digitalizador>section>aside>div>article.medicamentos>ul>li>div:nth-child(1) {
  width: 70%
}

article.digitalizador>section>aside>div>article.medicamentos>ul>li>div:nth-child(2) {
  width: 15%
}

article.digitalizador>section>aside>div>article.medicamentos>ul>li>div:nth-child(3) {
  width: 15%
}

article.digitalizador>section>aside>div>article.diagnostico {
  padding-left: 15px;
  margin-top: 20px;
}

article.digitalizador>section>aside>div>article.medicamentos {
  padding-left: 15px;
  margin: 20px 0;
}

article.digitalizador>section>aside>div>article.fecha>ul>li>div {
  padding-left: 15px;
}

article.digitalizador>section>aside>div>article.entidad>ul>li>div {
  padding-left: 15px;
}

article.digitalizador>section>aside>div>article>ul>li>div {
  margin: 0 .5rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

article.digitalizador>section>aside>div>article>ul>li>div>div:not(.icons) {
  width: 100%;
}

article.digitalizador>section>aside>div footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

article.digitalizador>section>aside>div footer button {
  margin: 0 .2rem;
}

article.digitalizador>section>article {
  margin-left: 0;
  width: 100%;
  height: calc(100vh - 69px);
}

article.digitalizador>section>article h4 {
  margin-bottom: 1rem;
}

article.digitalizador>section>article section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.05rem solid #0d47a1;
  height: 100%;
  width: 100%;
  position: relative
}


article.digitalizador>section>article section>div,
article.digitalizador>section>article section>div .pdfobject-container {
  width: 100%;
  height: 100%;
}

article.digitalizador>section>article section div h6 {
  font-size: 2rem;
  text-align: center;
}

article.digitalizador>section>article section div figure svg {
  font-size: 4rem;
}

article.digitalizador>section>article footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
  margin-right: 30px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 53px);
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.loading h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}

.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}

.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
</style>